import React from "react"
import SectionCurrentSponsors from "../../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrs from "../../images/svg/inline-svg/heading_past-spnsrs.svg"
import HeadingPastSpnsrsMobile from "../../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SectionHeroPage from "../../components/sections/heroPage"
import SectionTopThree from "../../components/sections/topThree"
import SectionQuoteBox from "../../components/sections/quoteBox"
import HeadingWhatToExpect from "../../images/svg/inline-svg/heading_what-to-expect.svg"
import HeadingWhatToExpectMobile from "../../images/svg/inline-svg/heading_what-to-expect_mobile.svg"
import HeadingWho from "../../images/svg/inline-svg/heading_who.svg"
import HeadingTeams from "../../images/svg/inline-svg/heading_teams.svg"
import HeadingTeamsMobile from "../../images/svg/inline-svg/heading_teams_mobile.svg"
import HeadingWhoMobile from "../../images/svg/inline-svg/heading_who_mobile.svg"
import HeadingNtwrk from "../../images/svg/inline-svg/heading_ntwrk.svg"
import HeadingNtwrkMobile from "../../images/svg/inline-svg/heading_ntwrk_mobile.svg"
import SectionMediaHalf from "../../components/sections/mediaHalf"
import SectionBigList from "../../components/sections/sectionBigList"
import SectionTripleList from "../../components/sections/sectionTripleList"
import SectionVideoCollage from "../../components/sections/sectionVideoCollage"
import SectionImageMosaic from "../../components/sections/sectionImageMosaic"

const quoteBoxQuotes = [
  {
    quote:
      "One of the things that I would say that I think Ascend does better than anyone else is providing time for people to truly network and get into conversations.",
    authorName: "Eric Fernandez",
    authorCredentials: "",
    authorTitle: "Director of Rehab",
    authorCompany: "Hyperice",
  },
  {
    quote:
      "Ascend is a meeting of the minds. It’s about getting to know what other practices are doing and to know that you're not alone in some of the daily struggles and how to improve those things all around.",
    authorName: "Gyn Stackhouse",
    authorCredentials: "",
    authorTitle: "Director of Admin Operations",
    authorCompany: "Rehab 2 Perform",
  },
  {
    quote: "The networking here at Ascend is incredible",
    authorName: "Katie Jarvis",
    authorCredentials: "",
    authorTitle: "Public Relations Director",
    authorCompany: "Dynamix Physical Therapy",
  },
  {
    quote:
      "I think Ascend has raised the bar in terms of expectations for what a conference should be. The content is progressive and forward thinking. Not just where we are today or maybe next year, where are we five years from now, ten years from now?",
    authorName: "Stephen Huntsman",
    authorCredentials: "",
    authorTitle: "Chief Compliance Officer",
    authorCompany: "Upstream Rehabilitation",
  },
  {
    quote:
      "As groups of practitioners, you find this nexus between where you are and what's possible. And that is the value of Ascend.",
    authorName: "Kendell Jno-FinnKendell Jno-Finn",
    authorCredentials: "",
    authorTitle: "PT, DPT",
    authorCompany: "M3 Performance Physical Therapy",
  },
]

const AttendPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              year
            }
          }
          headerImage: file(
            relativePath: { eq: "headers/hero-whattoexpect.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 712) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerImageMobile: file(
            relativePath: { eq: "headers/hero-whattoexpect-mobile.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 624) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bigNumber: file(relativePath: { eq: "big11.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          mediaHalfImage: file(relativePath: { eq: "TrackExperiences.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 607) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          who: file(relativePath: { eq: "Who.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 230) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerImage = data.headerImage.childImageSharp.fluid
        const headerImageMobile = data.headerImageMobile.childImageSharp.fluid
        const mediaHalfImage = data.mediaHalfImage.childImageSharp.fluid
        const who = data.who.childImageSharp.fluid
        const bigNumber = data.bigNumber.childImageSharp.fluid
        const year = data.site.siteMetadata.year

        return (
          <Layout>
            <Seo
              title={`What To Expect at Ascend ${year}`}
              location={`https://ascendevent.com/what-to-expect/`}
              description={`Learn more about why attending this year's Ascend ${year} physical therapy conference is good for your business.`}
            />
            <SectionHeroPage
              heading="What To Expect at Ascend 2024"
              showHeadingForScreenReaderOnly={true}
              subheading="An Experience That Will Stay With You"
              image={headerImage}
              mediaMediumOffset={0}
              buttonText="get your tickets"
              buttonLink="/"
              sectionHeading={<HeadingWhatToExpect />}
              ctaText="Get Your Tickets"
              ctaLink="/tickets/"
              mobileSectionHeading={<HeadingWhatToExpectMobile />}
              mobileImage={headerImageMobile}
            />
            <SectionTopThree />
            <SectionMediaHalf
              image={mediaHalfImage}
              videoUrl="https://share.vidyard.com/watch/w6fmjD5gYx282EzcdC3VvN"
              showVideo={false}
              sectionHeading=""
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={1}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={0}
              mediaLargeOrder={1}
              mediaMediumOrder={1}
              mediaSmallOrder={1}
              textWidthMedium={5}
              imageWidthMedium={6}
              mediumOrder={1}
              headingTag="h3"
              sectionBody="<h3>Track Experiences</h3><p>
                    We organize our educational sessions into three different tracks. Each track features hand-picked, industry-leading speakers who will deliver information and insights specific to you, your role, and your responsibilities. You’ll be equipped to take actionable plans back to your organization after the event.
                  </p>"
              ctaLink="/tickets/"
              ctaText="get your tickets"
            />
            <SectionBigList
              sectionHeading={<HeadingWho />}
              sectionHeadingMobile={<HeadingWhoMobile />}
              image={who}
              sectionBodyHeading="There's Something for Everyone"
              sectionBody="<p>Ascend brings rehab therapists together to learn, share ideas, and take their practices to the next level. That’s why we designed the event to allow attendees to collaborate and network—not just sit in sessions and absorb information—making it the perfect place to bring your whole team. There’s no better way to fuel innovation, inspire collaboration, and accelerate growth than doing it together.</p>"
              listContent="<p class='list-heading'>Rehab Therapy Practice Owners</p>
<p class='list-body'>Discover scalable revenue-boosting tactics, gain realistic, relatable advice from peers and experts, and prepare your practice for upcoming regulatory changes.</p>

<p class='list-heading'>Future Rehab Therapy Owners</p>
<p class='list-body'>Learn strategies to run and grow your future business, and network with industry leaders and successful private practice owners to gain realistic, relatable advice.</p>

<p class='list-heading'>Rehab Therapy Providers</p>
<p class='list-body'>Increase your value to employers by understanding the business side of practice, gain valuable career-planning insight, and prepare yourself for upcoming regulatory changes.</p>

<p class='list-heading'>Front Office Staff</p>
<p class='list-body'>Discover tactics to improve your front office workflow, gain valuable career-planning insight, and prepare yourself for upcoming regulatory changes.</p>

<p class='list-heading'>Rehab Therapy Business Executives</p>
<p class='list-body'>Learn how to leverage technology in multi-clinic or hospital-based practices, sharpen your leadership and hiring skills, and strengthen your company’s communication and culture.</p>

<p class='list-heading'>Therapy Billers</p>
<p class='list-body'>Learn tactics to improve your workflow and revenue cycle management processes, increase your value to employers by improving payments and better engaging patients, and gain valuable career-planning insight.</p>"
            />
            <SectionBigList
              sectionHeading={<HeadingTeams />}
              sectionHeadingMobile={<HeadingTeamsMobile />}
              sectionBodyHeading="Bring Your Crew"
              sectionBody="<p>Ascend attendees are the ones influencing the future of their companies’ business and strategic success. They’re the movers and shakers of the rehab therapy industry, and they hail from a wide variety of companies—both established and startup—including sole-practitioner clinics, multi-site private practices, and hospital-based facilities, where they work as single clinic owners, executives, therapists, front office staff, and billing managers. In other words, regardless of your practice size, style, or role within it, if you’re a rehab therapy professionals looking to take your practice to the next level, then Ascend is for you.</p>"
              listContent="<p class='list-heading'>Multiply Your Impact</p>
<p class='list-body'>Imagine the possibilities when every member of your team is immersed in the latest industry trends, gaining valuable insights and forging connections with like-minded professionals.</p>

<p class='list-heading'>Cultivate a Culture of Excellence</p>
<p class='list-body'>By attending together, you'll strengthen bonds, foster a learning culture, and ignite a passion for excellence that will reverberate throughout your organization.</p>

<p class='list-heading'>Maximize Return on Investment</p>
<p class='list-body'>Investing in your team's development is an investment in your company's future. With our group discounts and tailored experiences for teams, bringing everyone ensures maximum ROI for your conference investment.</p>"
            />
            <SectionQuoteBox quotes={quoteBoxQuotes} />
            <SectionTripleList />
            <SectionBigList
              sectionHeading={<HeadingNtwrk />}
              sectionHeadingMobile={<HeadingNtwrkMobile />}
              sectionBody="<p>Attending Ascend provides a variety of networking events that ignite conversations among attendees. By providing collaborative roundtable sessions, discussion-provoking content, and entertaining events, Ascend takes the stress out of networking so you can focus on having meaningful conversations that help you build your business.</p>"
              sectionBodyHeading="Connecting Made Easy"
              videoUrl="https://share.vidyard.com/watch/qPHh9UHMCwJXAMdpcDjZP6"
              showVideo={true}
              ctaText=""
              ctaLink=""
              listContent="
<p class='list-heading'>Exchange Talks</p>
<p class='list-body'>Engage in small peer-to-peer, topic-focused discussions where you can network, collaborate, and grow with other rehab therapy professionals.</p>

<p class='list-heading'>Ascend Connect</p>
<p class='list-body'>Connect with attendees through the mobile app and schedule meetups throughout Ascend Central.</p>

<p class='list-heading'>Lounges</p>
<p class='list-body'>Network with the professionals you best identify with in designated lounge areas.</p>


<p class='list-heading'>Welcome Reception</p>
<p class='list-body'>Kick off Ascend with a drink and some new friends! Network like a pro and forge lasting connections at the welcome reception.</p>

<p class='list-heading'>Happy Hour</p>
<p class='list-body'>Keep the conversation going! Round off your evenings at happy hour, located inside Ascend Central. This collaborative atmosphere is the perfect foundation for peer-to-peer conversations—plus, you can connect with our sponsors and exhibitors, the innovators of the industry.</p>

<p class='list-heading'>Dinners</p>
<p class='list-body'>Sign up for one of the networking group blind date events: Grab dinner with a small group of fellow attendees and enjoy an amazing local culinary hotspot. (We’ve done all the planning for you!)</p>"
              className="what-to-expect"
            />
            <SectionMediaHalf
              image={bigNumber}
              videoUrl=""
              showVideo={false}
              textSmallOrder={1}
              textMediumOrder={2}
              textLargeOrder={1}
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={0}
              textWidthMedium={6}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={1}
              mediaLargeOrder={2}
              mediaMediumOrder={1}
              mediaSmallOrder={2}
              imageWidthMedium={5}
              mediumOrder={2}
              sectionHeading=""
              headingTag="h3"
              sectionBody="<h3>The History of Ascend</h3><p>
                    As we prepare for the eleventh year of Ascend in Chicago, it's impossible not to look back at the incredible journey we've been on. From our modest start as a small gathering of therapy professionals to becoming the industry's premier business and networking conference, we're grateful for the fantastic community that has supported us over the years and the world-class speakers who have graced our stage.
                  </p>
                  <p>
                    It's been an incredible 11 years of learning, networking, and growth. We're proud of everything we've accomplished together—and we’re excited to keep pushing forward to help our community of therapy professionals thrive.</p>
           <p><strong>Let’s relive some of the incredible moments we’ve shared.</strong></p>"
            />
            <SectionVideoCollage
              videoUrl="https://share.vidyard.com/watch/6grGZTvRzvkL8rPJP5XK2x"
              videoUrl2="https://share.vidyard.com/watch/w6fmjD5gYx282EzcdC3VvN"
              videoUrl3="https://share.vidyard.com/watch/3pzWQaXeu2ieHo6yquMt3H"
              videoUrl4="https://share.vidyard.com/watch/J4BtU9nJNQaw9LgQentwWq"
              videoUrl5="https://share.vidyard.com/watch/rHR4vbb8kGoGmZZEBdP3xg"
              videoUrl6="https://share.vidyard.com/watch/9Lw25BT1QrGQzjtDoabz4j"
              videoUrl7="https://share.vidyard.com/watch/knFbG955t5gm89Jgjpoe4R"
            />
            <SectionImageMosaic />
            <SectionCurrentSponsors
              sectionHeading={<HeadingPastSpnsrs />}
              sectionHeadingMobile={<HeadingPastSpnsrsMobile />}
            />
          </Layout>
        )
      }}
    />
  )
}

export default AttendPage
